.react-multiple-carousel__arrow {
    display: none;
}
.banner {
    max-height: 23.9583vw;
    padding-left: 8.59375vw;
    padding-right: 7.552083vw;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.banner-left {
    width: 42.7083vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.banner-head {
    font-family: 'Poppins Medium';
    font-size: 2.864583vw;
    line-height: 4.27083vw;
    margin-top: 0.5vw;
}
.banner-subhead {
    font-size: 1.822916vw;
    line-height: 4.27083vw;
    color:#E2C621;
}
.banner-watcho-devices {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    padding-top: 1.25vw;
}
.banner-watcho-devices>div {
    font-family: Poppins Light;
    font-size: 1.04166vw;
    line-height: 1.66666vw;
    width: 7.552083vw;
}

.banner-watcho-devices>div p {
    font-size: max(10px,1.25vw);
}

ul.list-devices {
    display: flex;
    gap: 20px;
    align-items: baseline;
}
ul.list-devices li {
    text-align: center;
    font-size: max(10px,.83333333vw);
}
.banner-right {
    width: 37.5vw;
    margin-top: -1.78vw;
}
.banner-right img {
    max-width: 100%;
}
.list-devices img {
    max-width: max(3.1489583vw);
}

.react-multi-carousel-dot-list {
    position: absolute;
    bottom:max(15px,1.5625vw) !important;
}
.react-multi-carousel-dot button {
    border-width: 1px !important;
}
.react-multi-carousel-dot--active button {
    background: #eb5540 !important;
    border-width: 1px !important;
    width: max(12px) !important;
    height: max(12px) !important;
}

@media screen and (max-width:767px) {
    .react-multi-carousel-dot-list {
        bottom: 50.38461vw !important;
    }
    .banner {
        padding-left: 3.472vw;
        padding-right: 3.472vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        max-height: inherit;
    }
    .banner-right img {
        max-width: 100%;
        border-bottom: 0.51282vw solid #ffffff50;
    }
    .banner-left {
        width: 100%;
        order:2
    }
    .banner-right {
        width: 100%;
        order:1
    }
    .banner-head {
        font-size: 5.9778vw;
        line-height: 7.77vw;
        margin-top: 10.5vw;
        letter-spacing: 0;
        padding-bottom: 2.564102vw;
        text-align: center;
    }
    .banner-subhead {
        font-size: 3.68vw;
        line-height: 6.11vw;
        color: #E2C621;
        text-align: center;
        padding-bottom: 0.564102vw;
    }
    .banner-watcho-devices {
        gap: 20px;
        align-items: center;
        padding-top: 1.25vw;
        flex-direction: column;
    }
    .banner-watcho-devices > div {
        font-size: 3.58vw;
        line-height: 5vw;
        font-family: 'Poppins Light';
        width: 100%;
        text-align: center;
    }

    ul.list-devices {
        display: flex;
        gap: 3.3vw;
        align-items: baseline;
        font-size: 3.05vw;
    }
    .list-devices img {
        max-width: max(12.489583vw);
    }
    .banner-watcho-devices>div p {
        font-size: max(3.589743vw);
        line-height:  max(5.589743vw);;
    }
    
}